import React from "react"
import { graphql } from "gatsby"
import Layout from "../components/layout"
import Seo from "../components/seo"
import { GatsbyImage } from "gatsby-plugin-image"
import parse from "html-react-parser"

const PrivacyPolicy = props => {
  // const siteTitle = data.site.siteMetadata.title
  
  let {
    location,
    data: {
      wpPage: {
        title,
        excerpt,
        content,
        featuredImage: {
          node: {
            altText,
            localFile: {
              childImageSharp: { gatsbyImageData },
            },
          },
        },       
        seo,
        contentTypeName,
        slug,
      },
    },
  } = props

  // console.log(projects)

  return (
    <Layout location={location}>
      <Seo bodyClasses={`${contentTypeName} ${slug}`} seo={seo} />

      <section className="intro">
        {gatsbyImageData && (
          <GatsbyImage image={gatsbyImageData} alt={altText} loading="eager" />
        )}
        <div className="content white">
          <div className="container">
            <div className="row">
              <div className="col-md-7 col-lg-7 copy">
                <blockquote className="text-uppercase">
                  {parse(title)}
                </blockquote>
                <h1>{parse(excerpt)}</h1>
              </div>
            </div>
          </div>
        </div>
      </section>

      <section className="white">
        <div className="container">
          <div className="row">
            <div className="col-md-12">
              <div className="row">
                <div className="col-md-11 col-lg-12 col-xl-10 col-xxl-10">
                  <article>{parse(content)}</article>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </Layout>
  )
}

export default PrivacyPolicy

export const pageQueryPrivacy = graphql`
  {
    wpPage(slug: { eq: "privacy-policy" }) {
      title
      excerpt
      content
      featuredImage {
        node {
          altText
          localFile {
            childImageSharp {
              gatsbyImageData
            }
          }
        }
      }     
      seo {
        ...WpYoastSEO
      }
      slug
      contentTypeName
    }
  }
`
